/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = () => {
  const tagManagerCookie = 'gatsby-gdpr-google-tagmanager=true;'
  const cookieIndex = document.cookie
    .split(';')
    .findIndex(cookie => cookie.match(/google-tagmanager/g))

  if (cookieIndex === -1) {
    document.cookie = document.cookie + tagManagerCookie
  }
}
